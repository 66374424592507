import { isBlank } from 'adready-api/helpers/common';
import { isEqual } from 'underscore';
import { get } from 'vuex-pathify';
import { formatDateForAPI } from '~/util/apiDateFormat';
import * as util from '~/util/utility-functions';

const data = function initData() {
  return {};
};

const computed = {
  advertiser: get('common/advertiser'),
  account: get('common/account'),
  dates: get('dashboard/dates'),
  universalPixelId: get('common/universalPixelId'),
  mediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
  selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
  selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
  selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
  selectedIOIds: get('dashboard/GET_SELECTED_IO_IDS'),
  selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
  showUniques: get('dashboard/filters@showUniques'),
  attribution: get('dashboard/filters@attribution'),
  methodology: get('dashboard/filters@methodology'),
  conversionWindow: get('dashboard/filters@conversionWindow'),
  selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),

  selectedEventLabel() {
    return isBlank(this.selectedEvent.eventLabel) ? 'Event' : this.selectedEvent.eventLabel;
  },
  selectedEventMetric() {
    return isBlank(this.selectedEvent.metric) ? 'CPA' : this.selectedEvent.metric;
  },
  defaultSortOrder() {
    return this.selectedEvent.isRevenueEvent || this.selectedEvent.isFalseEvent ? 'desc' : 'asc';
  },
  payload() {
    const result = {
      advertiser: this.advertiser ? this.advertiser.name : '',
      client: this.account ? this.account.name : '',
      xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
      mediaType: this.mediaTypes,
      ioIds: this.selectedIOIds,
      endDate: formatDateForAPI(this.dates.endDate),
      startDate: formatDateForAPI(this.dates.startDate),
      pixel: this.universalPixelId,
      event: this.selectedEventLabel,
      category: isBlank(this.selectedEvent.category) ? 'none' : this.selectedEvent.category,
      subCategory: this.selectedEvent.subCategories,
      conversionWindow: this.conversionWindow,
      showUniques: this.showUniques,
      attribution: this.attribution,
      methodology: this.methodology,
      adGroups: this.selectedAdGrpOptStr,
      audience: this.selectedAudiences,
      publisher: this.selectedPublishers,
      creative: this.selectedCreatives,
    };
    if (this.dates.compareWith) {
      result.compareStartDate = formatDateForAPI(this.dates.compareStartDate);
      result.compareEndDate = formatDateForAPI(this.dates.compareEndDate);
    }
    return result;
  },
};

const methods = {
  validateParams(payload, advertiser) {
    const advertiserId = advertiser ? advertiser.id : null;
    if (isBlank(advertiserId)) {
      return false;
    }
    const keys = [
      'groupBy',
      'client',
      'advertiser',
      'xandrId',
      'pixel',
      'category',
      'startDate',
      'endDate',
    ];
    return !util.isAnyBlank(payload, keys);
  },
  sortRows(rows) {
    // 1. Separate out rows with infinity value
    const infinityRows = rows.filter((r) => r.isValueInfinity);
    const sortedRows = rows.filter((r) => !r.isValueInfinity);

    // 2. Sort the remaining rows
    sortedRows.sort((a, b) => {
      if (this.defaultSortOrder === 'desc') {
        return b.value - a.value;
      }
      return a.value - b.value;
    });

    // 3. Add rows with infinity value back to the sorted rows so that they always appear at bottom
    infinityRows.forEach((r) => sortedRows.push(r));

    return sortedRows;
  },
  transformToRowItem(item) {
    const info = this.getSliderInfoContents(item);
    let key = this.selectedEvent.isRevenueEvent ? 'roas' : 'cpa';
    if (this.selectedEvent?.isFalseEvent) {
      key = this.selectedEvent?.isVcrEvent ? 'vcr' : 'ctr';
    }
    if (this.selectedEvent?.isIncrementalReach) {
      key = 'incrementalReachPercentage';
    }
    const objRef = item[key];
    const type = util.getValueFormat(key);
    return {
      // nameColumnStyle: text | logo | both | preview
      nameColumnStyle: 'text',
      value: objRef?.currentValue,
      isValueInfinity: objRef?.isCurrentInfinity,
      valueType: type,
      change: objRef?.change,
      isChangeInfinity: objRef?.isChangeInfinity,
      reverseColors: !this.selectedEvent.isRevenueEvent,
      info,
    };
  },
  buildSliderInfoObject(item, head, fieldName) {
    const field = item[fieldName];

    return {
      head,
      value: field?.currentValue,
      isInfinity: field?.isCurrentInfinity,
      valueType: util.getValueFormat(fieldName),
    };
  },
  getSliderInfoContents(item) {
    const info = [];
    if (this.selectedEvent.isRevenueEvent) {
      info.push(
        this.buildSliderInfoObject(item, 'Imps', 'impressions'),
        this.buildSliderInfoObject(item, 'Spend', 'spend'),
        this.buildSliderInfoObject(item, 'CPM', 'cpm'),
        this.buildSliderInfoObject(item, 'Revenue', 'revenue'),
        this.buildSliderInfoObject(item, 'CPA', 'cpa'),
        this.buildSliderInfoObject(item, 'ROAS', 'roas'),
        this.buildSliderInfoObject(item, 'PM', 'pm')
      );
    } else {
      const falseMetricKey = this.selectedEvent.isVcrEvent ? 'vcr' : 'ctr';
      const falseDimensionKey = this.selectedEvent.isVcrEvent ? 'videoCompletions' : 'clicks';
      let metricLabel = `${this.selectedEventMetric}`;
      let metricKey = 'cpa';
      if (this.selectedEvent.isFalseEvent) {
        metricKey = falseMetricKey;
      } else if (this.selectedEvent.isIncrementalReach) {
        metricLabel = 'Unique Reach %';
        metricKey = 'incrementalReachPercentage';
      }
      const dimensionLabel = `${this.selectedEventLabel}`;
      const dimensionKey = this.selectedEvent.isFalseEvent ? falseDimensionKey : 'visits';

      if (dimensionLabel !== 'Incremental Reach') {
        info.push(
          this.buildSliderInfoObject(item, 'Imps', 'impressions'),
          this.buildSliderInfoObject(item, 'Spend', 'spend'),
          this.buildSliderInfoObject(item, 'CPM', 'cpm'),

          this.buildSliderInfoObject(item, metricLabel, metricKey),
          this.buildSliderInfoObject(item, dimensionLabel, dimensionKey)
        );
      } else {
        info.push(
          this.buildSliderInfoObject(item, 'Imps', 'impressions'),
          this.buildSliderInfoObject(item, 'Spend', 'spend'),
          this.buildSliderInfoObject(item, 'CPM', 'cpm'),

          this.buildSliderInfoObject(item, metricLabel, metricKey),
          this.buildSliderInfoObject(item, 'Unique Reach', 'ireach')
        );
      }
      if (!this.selectedEvent.isFalseEvent) {
        info.push(this.buildSliderInfoObject(item, 'PM', 'pm'));
      }
    }
    return info;
  },
  getCommonHeader() {
    return {
      name:
        this.selectedEventMetric === 'incrementalReachPercentage'
          ? 'Unique Reach %'
          : this.selectedEventMetric,
      sortKey: 'value',
      initialSortDirection: this.selectedEvent.isRevenueEvent ? 'desc' : 'asc',
    };
  },
};

const watch = {
  payload: {
    deep: true,
    async handler(n, o) {
      if (isEqual(n, o)) {
        return;
      }
      if (n.advertiser === o.advertiser) {
        try {
          await this.loadPerformanceData(n);
        } catch (err) {
          console.error('Error in updating performance table ->', err.message, err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        }
      }
    },
  },
};

export default { computed, data, methods, watch };
